import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchDonation, fetchNavbarData } from "../../api/API";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Donors.css";
import FullpageLoader from "../../common/HomeSliderLoader/FullpageLoader";

const Donors = () => {
  const [blogData, setBlogData] = useState({});
  const [loader, setLoader] = useState(true);

  const [country, setCountry] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();
  const notify = (msg) => {
    toast.error(msg, {
      position: "top-right",
    });
  };

  const location = useLocation();

  useEffect(() => {
    const params = location.search;
    // Parse query parameters using URLSearchParams
    const queryParams = new URLSearchParams(location.search);

    // Get the 'invalid_branch' parameter value
    const invalidBranchParam = queryParams.get("invalid_branch");

    // Set state based on the query parameter value
    if (invalidBranchParam === "true") {
      notify("Please select branch");
      if (location.search) {
        // Remove query parameters by navigating to the same path without them
        navigate(location.pathname, { replace: true });
      }
    }
  }, [location]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetchDonation({
  //         url: apiUrl,
  //         page: "donation_blog",
  //       });
  //       if (response.data.status === true) {
  //         setBlogData(response.data.responseBody);
  //         console.log("Update successful", response.data.responseBody);
  //       } else {
  //         console.error("Error updating data:", response.data);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     } finally {
  //       setLoader(false); // Set loader to false after data is fetched
  //     }
  //   };
  //   fetchData();
  // }, []);

  const handleOnlineClick = (blog) => {
    console.log("india", blog);
    navigate(`/donationNew/${blog.id}`, {
      state: {
        donation_india: blog.donation_india,
        donation_usa: blog.donation_usa,
        donation_abroad: blog.donation_abroad,
        donation_others: blog.donation_others,
      },
    });
  };

  const getTitle = (key) => {
    if (key === "list_blogs_usa") {
      return "Donation for Usa";
    }
    if (key === "list_blogs_india") {
      return "Donation for India";
    }
    if (key === "list_blogs_others") {
      return "Donation for others";
    }
    if (key === "list_blogs_abroad") {
      return "Donation for Abroad";
    }
    return "Donation";
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetchNavbarData({
  //         url: process.env.REACT_APP_API_URL,
  //       });
  //       if (response.data.status == true) {
  //         const siteCountry = response.data.responseBody.site.country;
  //         setCountry(siteCountry);
  //         console.log(
  //           "response.data.responseBody.site1",
  //           response.data.responseBody
  //         );
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     } finally {
  //       setLoader(false); // Set loader to false after data is fetched
  //     }
  //   };

  //   fetchData();
  // }, []);

  useEffect(() => {
    // const fetchNavbar = fetchNavbarData({
    //   url: process.env.REACT_APP_API_URL,
    // }).then((response) => {
    //   if (response.data.status) {
    //     setCountry(response.data.responseBody.site.country);
    //     console.log("Navbar Data:", response.data.responseBody);
    //   }
    // });
  
    const fetchBlog = fetchDonation({
      url: apiUrl,
      page: "donation_blog",
    }).then((response) => {
      if (response.data.status) {
        setBlogData(response.data.responseBody);
        console.log("Blog Data:", response.data.responseBody);
      } else {
        console.error("Error updating blog data:", response.data);
      }
    });
  
    // Wait for both requests to finish
    Promise.all([ fetchBlog])
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => setLoader(false)); // Set loader to false only after both requests complete
  }, []);
  
  if (loader) {
    return <FullpageLoader />; // Show loader until both requests finish
  }
  

  const renderBlogSection = (key, blogs) => (
    <>
      <ToastContainer />
      <h2 className="section_title pt-5">{getTitle(key).toUpperCase()}</h2>
      <Grid container spacing={3} justifyContent="center" className="pt-5">
        {blogs.map((blog, index) => {
          console.log("blog", blog);
          const blogDetails = JSON.parse(blog.details);
          return (
            <Grid key={index} item xs={12} sm={12} md={12} lg={6}>
              <div className="bank-info-wrap">
                <div className="container">
                  <h4 className="bank-details">{blog.blog_title}</h4>
                  <div className="bank-info">
                    <div>
                      <ul className="info-text">
                        {blogDetails.map((item, i) => (
                          <li key={i}>{item.title} :</li>
                        ))}
                      </ul>
                    </div>
                    <div>
                      <ul className="info-details">
                        {blogDetails.map((item, i) => (
                          <li key={i}>{item.detail ? item.detail : "-"}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                {blog.is_online === "1" && (
                  <button
                    className="donors-btn"
                    onClick={() => handleOnlineClick(blog)}
                  >
                    Online
                  </button>
                )}
              </div>
            </Grid>
          );
        })}
      </Grid>
    </>
  );

  const orderedKeys = () => {
    const defaultOrder = [
      "list_blogs_usa",
      "list_blogs_india",
      "list_blogs_others",
      "list_blogs_abroad",
    ];
    if (country === "INDIA") {
      return [
        "list_blogs_india",
        "list_blogs_usa",
        "list_blogs_others",
        "list_blogs_abroad",
      ];
    }
    if (country === "USA") {
      return [
        "list_blogs_usa",
        "list_blogs_india",
        "list_blogs_others",
        "list_blogs_abroad",
      ];
    }
    return defaultOrder;
  };

  return (
    <Container className="pt-5">
      {/* {Object.keys(blogData).map((key) => {
        if (Array.isArray(blogData[key]) && blogData[key].length > 0) {
          return <div key={key}>{renderBlogSection(key, blogData[key])}</div>;
        }
        return null;
      })} */}
      {orderedKeys().map((key) => {
        if (Array.isArray(blogData[key]) && blogData[key].length > 0) {
          return <div key={key}>{renderBlogSection(key, blogData[key])}</div>;
        }
        return null;
      })}
    </Container>
  );
};

export default Donors;
