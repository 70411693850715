import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import "./Thankyou.css";
import { useNavigate } from "react-router-dom";

const ThankYou = () => {
  const [transactionId, setQueryParam] = useState("");
  const [message, setMessage] = useState("");
  const [url, setUrl] = useState("");

  console.log("url", url);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const transactionId = params.get("transaction_id");
    const message = params.get("message");
    const url = params.get("url");

    if (transactionId) {
      setQueryParam(transactionId);
    }
    if (message) {
      setMessage(message);
    }
    if (url) {
      setUrl(url);
    }
  }, []);

  const handleDownload = () => {
    if (url) {
      // Fetch the PDF content
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          // Create a temporary URL for the Blob
          const blobUrl = URL.createObjectURL(blob);
          // Create a temporary anchor element
          const link = document.createElement("a");
          link.href = blobUrl;
          link.download = "Invoice.pdf"; // Set the file name here
          link.style.display = "none";
          // Append the anchor to the body
          document.body.appendChild(link);
          // Trigger a click event on the anchor to initiate the download
          link.click();
          // Remove the temporary anchor element
          document.body.removeChild(link);
          // Revoke the Blob URL
          URL.revokeObjectURL(blobUrl);
        })
        .catch((error) => {
          console.error("Error fetching PDF:", error);
        });
    }
  };
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };

  return (
    <>
      <Container className="thankyou-message">
        {url ? (
          <Card className="thankyou-message">
            <div className="thankyou">
              <div className="thankyou-title">
                <div className="thankyou-text">Your transaction id:-</div>
                <div className="thankyou-text">Your payment :-</div>
                <div className="thankyou-text">Download pdf :-</div>
              </div>

              <div className="thankyou-title">
                <div className="thankyou-data">{transactionId}</div>
                <div className="thankyou-data">{message}</div>
                <div className="thankyou-data">
                  <button onClick={handleDownload} className="download-btn">
                    Download
                  </button>
                </div>
              </div>
            </div>
          </Card>
        ) : (
          <Card className="p-5">
            <h2 style={{textAlign:"center",color:"var(--text-color)",fontSize:"30px",fontWeight:700}}>Your payment is failed!</h2>
            <br />

            <div className="thankyou-data">
              <button onClick={handleClick} className="download-btn" style={{padding:"9px 19px",marginLeft:"auto",marginRight:"auto",display:"block"}}>
                OK
              </button>
            </div>
          </Card>
        )}
      </Container>
    </>
  );
};

export default ThankYou;
